import React from 'react';
import ReactDOM from 'react-dom/client';
import {unstable_HistoryRouter as HistoryRouter} from "react-router-dom";
import {history} from "./utils/history";
import App from './App';
import './index.css';
import './assets/font/iconfont.css';
import {ConfigProvider} from "antd";
// import moment from "moment";
import zhCN from 'antd/lib/locale/zh_CN';
// import 'moment/dist/locale/zh-cn';

import dayjs from "dayjs";
import "dayjs/plugin/updateLocale";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

// moment.locale('zh-cn')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ConfigProvider locale={zhCN}>
      <HistoryRouter history={history}>
          <App />
      </HistoryRouter>
    </ConfigProvider>
  // </React.StrictMode>
);
