import React, {Suspense} from 'react';
import 'antd/dist/reset.css';
import './App.css';
import {useRoutes} from "react-router-dom";
import routes from "./routes";
import {ConfigProvider} from 'antd';


function App() {
    const routeElement = useRoutes(routes);
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#3CBDC6',
                }
            }}>
            <div className="App">
               <Suspense
                    fallback={
                        <div style={{textAlign: 'center', marginTop: 200}}>
                            页面加载中,请稍后...
                        </div>
                    }>
                   {routeElement}
               </Suspense>
            </div>
        </ConfigProvider>
    );
}

export default App;
