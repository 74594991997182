import {lazy} from "react";
import {Navigate} from "react-router-dom";

const Layout = lazy(() => import('@/pages/Layout'))
const Login = lazy(() => import('@/pages/Login'))
const TestRegisterPage = lazy(() => import('@/pages/TestRegisterPage'))
const TestRegister = lazy(() => import('@/pages/TestRegister'))
const RecordTestResult = lazy(() => import('@/pages/RecordTestResult'))
const RecordTestResultForTestReport = lazy(() => import('@/pages/RecordTestResultForTestReport'))
const AppointmentStoreManagement = lazy(() => import('@/pages/AppointmentStoreManagement'))
const TestReportPage = lazy(() => import('@/pages/TestReportPage'))
const TestReport = lazy(() => import('@/pages/TestReport'))
const StaffManagement = lazy(() => import('@/pages/StaffManagement'))
const AntigenTestList = lazy(() => import('@/pages/AntigenTestList'))

const routes = [
    {
        path: 'login',
        element: <Login />
    },
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: 'testRegister',
                element: <TestRegisterPage />,
                children: [
                    {
                        path: 'index',
                        element: <TestRegister />,
                    },
                    {
                        path: 'record',
                        element: <RecordTestResult />,
                    },
                ]
            },
            {
                path: 'appointmentStoreManagement',
                element: <AppointmentStoreManagement />,
            },
            {
                path: 'testReport',
                element: <TestReportPage />,
                children: [
                    {
                        path: 'index',
                        element: <TestReport />,
                    },
                    {
                        path: 'record',
                        element: <RecordTestResultForTestReport />,
                    },
                ]
            },
            {
                path: 'staffManagement',
                element: <StaffManagement />,
            },
            {
                path: 'antigenTestList',
                element: <AntigenTestList />,
            },
            {
                path: "",
                element: <Navigate to="testRegister/index" replace />
            }
        ],
    }
]

export default routes;
